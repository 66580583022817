import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";

import Logo from "../../../assets/image/g4slogo.png";

const Login = () => {
	const emailRef = useRef()
	const passwordRef = useRef()
	const { login } = useAuth()
	const [error, setError] = useState("")
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()

	async function handleLogin(e) {
		e.preventDefault()
		try {
			setError("")
			setLoading(true)
			const res = await login(emailRef.current.value, passwordRef.current.value)
			window.localStorage.setItem('token', res?.user?.accessToken)
			navigate("/", { replace: true });
		} catch {
			setError("Invalid credentials!")
		}

		setLoading(false)
	}

	return (
		<div className="auth-layout">
			<div className="auth-container">
				<div className="image-wapper">
					<h1>Welcome Back</h1>
				</div>
				<div className="form-wapper">
					<div className="form-header text-center">
						<div className="d-flex justify-content-center w-100">
							<Link to={'/'} >
								<img src={Logo} width="100px" alt="Logo" />
							</Link>
						</div>
						<h2>Login</h2>
					</div>
					<div className="form-body">
						<form>
							<div className="input-wrapper">
								<label htmlFor="email">Email</label>
								<input id="email" type="email" name="email" placeholder="Email" ref={emailRef} />
							</div>
							<div className="input-wrapper">
								<label htmlFor="password">Password</label>
								<input id="password" type="password" name="password" placeholder="**********" ref={passwordRef} required />
								<span className="icon show-pass"><i className="fi-rr-eye"></i></span>
							</div>
							<div className="input-wrapper more-action">
								<label>
									<input id="remember-me" type="checkbox" name="remember_me" required />Remember Me
								</label>
								<Link to="/auth/password/reset">Forgot Password?</Link>
							</div>
							<button type="button" disabled={loading} onClick={handleLogin}>Sign In</button>
						</form>
					</div>
					{
						error && (
							<div className="form-footer text-center">
								<p className="error">Invalid credentials!</p>
							</div>
						)
					}

				</div>
			</div>
		</div>
	)
}

export default Login
