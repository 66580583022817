const UserDetail = (props) => {
  const { handleClose, user } = props;
  return ( 
    <>
      <div>
        <div className="title">
          <h4>User Detail</h4>
        </div>
        <div>
          <table className="table">
            <tr>
              <td>Name:</td> <td>{user?.displayName}</td>
            </tr>
            <tr>
              <td>Email:</td> <td>{user?.email}</td>
            </tr>
          </table>
        </div>
        <div className="d-flex justify-content-end">
          <button className="btn btn-default" onClick={ () => { handleClose()}}>Close</button>
        </div>
      </div>
    </>
  )
}

export default UserDetail;