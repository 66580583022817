import React, { useEffect, useState, useContext } from "react";
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';

import { MdClose } from 'react-icons/md';
import { fabric } from 'fabric';

import IconDetail from "../Shared/component/IconDetail";
import SceneContext from '../../context/SceneContext';

import { backButtonProps, rectCommonProps, iconCommonProps, textCommonProps } from './utils';

const RenderCanvas = () => {

  const contextData = useContext(SceneContext);

  const { editor, onReady } = useFabricJSEditor();

  const [update, setUpdate] = useState(false);
  const [viewCanvas, setViewCanvas] = useState(true);
  const [showRightMenu, setShowRightMenu] = useState(false);
  const [video, setVideo] = useState(null);
  const [selectedIcon, setSelectedIcon] = useState({});
  const [scenes, setScenes] = useState(contextData.scenesData);
  const [selectedScene, setSelectedScene] = useState(contextData.selectedScene);
  const [previousScene, setPreviousScene] = useState([contextData.selectedScene]);

  const [screen, setScreen] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 64
  });

  const functionHandler = {
    handleIconClick: handleIconClick,
    handleRectClick: handleRectClick,
    handleBackButtonClick: handleBackButtonClick
  }

  contextData['renderScene'] = renderScene;
  contextData['showHideIcon'] = showHideIcon;
  contextData['handleIconClick'] = handleIconClick

  useEffect(() => {
    if (editor) {
      editor.canvas.off('mouse:down');
      editor.canvas.on('mouse:down', (e) => {
        setShowRightMenu(false);
        if (e.target?.type === 'icon') {
          functionHandler[e.target?.onClick](e.target.id, selectedScene);
        }
        if (e.target?.type === 'rect') {
          if (e.target?.onClick === 'onChaneNewRect') {
            functionHandler[e.target?.onClick](e.target);
          } else {
            functionHandler[e.target?.onClick](e.target.id);
          }
        }
        if (e.target?.type === 'backButton') {
          functionHandler[e.target?.onClick]();
        }
      })

      editor.canvas.off('mouse:over');
      editor.canvas.on('mouse:over', (e) => {
        if (e.target?.type === 'rect') {
          var text = new fabric.Textbox(e.target.label, {
            ...textCommonProps,
            fontFamily: "Gill Sans",
            // width: e.target.width,
            width: 200,
            top: e.target.top,
            left: e.target.left
            
          });
          editor.canvas.add(text);
        }
      })

      editor.canvas.off('mouse:out');
      editor.canvas.on('mouse:out', (e) => {
        if (e.target?.type === 'rect') {
          editor?.canvas.getObjects().forEach(function (o) {
            if(o.type === 'text') {
              editor.canvas.remove(o);
            }
          });
        }
      })
    }

    if (editor?.canvas && !update) {
      if (scenes.length > 0) {
        const scene = scenes.find(s => s.root === true);
        renderScene(scene)
        setUpdate(true)
      }
    }

  }, [fabric, editor, scenes])

  useEffect(() => {
    if (previousScene.length > 0 && !selectedScene.root) {
      setTimeout(() => {
        drawBackButton();
      }, 200);
    }
  }, [previousScene, selectedScene]);

  useEffect(() => {
    let resizeId;
    window.addEventListener('resize', (e) => {
      clearTimeout(resizeId);
      resizeId = setTimeout(doneResizing, 500);
      setScreen({
        width: window.innerWidth,
        height: window.innerHeight - 64
      })
    })

  }, []);

  useEffect(() => {
    if (editor?.canvas) {
      resizeCanvas();
    }
  }, [screen])


  function doneResizing() {
    resizeDiv();
  }

  function resizeCanvas() {
    const ratio = editor?.canvas.getWidth() / editor?.canvas.getHeight();
    const outerCanvasContainer = document.querySelector(".canvas-view");
    const containerWidth = outerCanvasContainer.clientWidth;
    const containerHeight = outerCanvasContainer.clientHeight;
    const scale = containerHeight / editor?.canvas.getHeight();
    const zoom = editor?.canvas.getZoom() * scale;

    const canvasHeight = containerWidth / ratio;
    const canvasWidth = containerWidth;
    editor?.canvas.setDimensions({
      width: canvasWidth,
      height: canvasHeight,
    });
    editor?.canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
  }

  async function checkImage(path) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = path
      img.onload = (e) => {
        resolve(path)
      }
      img.onerror = () => {
        reject()
      }
    })
  }

  function renderScene(scene) {
    if (Object.keys(scene).length !== 0) {
      checkImage(scene.imageLink).then(path => {
        contextData['selectedScene'] = scene;
        setSelectedScene(scene);
        editor.canvas.interactive = false;

        editor?.canvas.getObjects().forEach(function (o) {
          editor.canvas.remove(o);
        });

        setCanvasBackGround(path);

        if (scene?.icons) {
          scene.icons.forEach(i => {
            drawIcon(i)
          })
        }
        if (scene?.rectangles) {
         
          scene.rectangles.forEach(r => {
            drawRectangle(r)
          })
        }
        setTimeout(() => {
          resizeDiv();
          scaleObjects();
        }, 100);
        contextData['handleSceneChange']();
      })
    }
  }

  function setCanvasBackGround(imgUrl) {
    fabric.Image.fromURL(imgUrl, function (oImg) {
      if (window.innerWidth < 1367) {
        oImg.scaleToWidth(screen.width);
      } else {
        oImg.scaleToHeight(screen.height);
      }
      editor?.canvas.setBackgroundImage(oImg);
      editor?.canvas.setZoom(1);
    });
  }

  function playVideo(scene) {
    const selector = document.querySelector('.canvas-container')
    const width = selector.clientWidth;
    const height = selector.clientHeight;
    setVideo(
      <video autoPlay={true} onPlay={() => {videoPlay()}} onLoadStart={() => { onVideoLoading(scene) }} onEnded={() => videoEnd(scene)} style={{ height: `${height}px` }} className="canvas-video">
        <source src={scene.videoLink} type="video/mp4" />
        Sorry, your browser doesn't support embedded videos.
      </video>
    )

  }

  function onVideoLoading(scene) {
    setTimeout(() => {
      renderScene(scene);
    }, 500)
  }

  function videoEnd(scene) {
    
    setVideo(null);
  }

  function videoPlay() {
    const selector = document.querySelector('.canvas-container')
    const width = selector.clientWidth;
    const video = document.querySelector('.canvas-video')
    const w = video.offsetWidth
    const s = parseFloat((width/w ).toFixed(5))
    video.style.transform = `scaleX(${s})`;
  }

  function drawIcon(iconDetail) {
    const { location, iconPath, iconId } = iconDetail;
    fabric.Image.fromURL(iconPath, function (IconNoText) {
      var IconNoText1 = IconNoText.set(
        {
          onClick: 'handleIconClick',
          id: iconId,
          left: parseFloat(location.left),
          top: parseFloat(location.top),
          ...iconCommonProps
        });
      editor?.canvas.add(IconNoText1);
    });
  }

  function drawRectangle(rectDetail, type = 'rect') {
    const { location, area, linkedView, label } = rectDetail
    const rect = new fabric.Rect({
      onClick: 'handleRectClick',
      label: label,
      id: linkedView,
      left: parseFloat(location.left),
      top: parseFloat(location.top),
      width: parseFloat(area.width),
      height: parseFloat(area.height),
      type: type,
      ...rectCommonProps
    })
    editor.canvas.add(rect);

  }

  function drawBackButton() {
    const backButton = new fabric.Triangle(backButtonProps)
    editor.canvas.add(backButton);
  }

  function handleBackButtonClick() {
    const scene = previousScene.pop();
    if (scene) {
      renderScene(scene);
    }
  }

  function handleIconClick(id, scene) {
    if (Object.keys(scene).length !== 0) {
      const icon = scene?.icons?.find(i => i.iconId === id);
      setSelectedIcon(icon);
      contextData['handleProductView'](icon);
      checkImage(icon.detail.image).then(path => {
        setShowRightMenu(true);
      }).catch(error => {
        icon.detail.image = 'images/products/broken.png';
        setShowRightMenu(true);
      })

    }

  }

  function handleRectClick(id) {
    const scene = scenes.find(s => s.id === id);
    const exist = previousScene.find(s => s.id === selectedScene.id);
    if (!exist) {
      const ps = [...previousScene, selectedScene];
      setPreviousScene(ps)
    }

    if (scene?.videoLink) {
      playVideo(scene) // To Play video

    } else {
      renderScene(scene);
    }
  }

  function showHideIcon(state) {
    editor?.canvas.getObjects().forEach(function (o) {
      if (o.type === 'icon') {
        let op = 0;
        state ? op = 1 : op = 0
        o.opacity = op
      }
    });

    editor.canvas.renderAll();
  }

  function scaleObjects() {
    const xScale = (editor?.canvas.backgroundImage.width * editor?.canvas.backgroundImage.scaleX) / 1536;
    // const yScale = editor.canvas.height / 865;

    const yScale = (editor?.canvas.backgroundImage.height * editor?.canvas.backgroundImage.scaleY) / 865;

    var objects = editor?.canvas.getObjects();
    for (var i in objects) {
      const top = (objects[i].top * yScale + 0.5);
      const left = (objects[i].left * xScale + 0.5);
      if (objects[i].type === 'icon') {
        objects[i].scaleX = xScale - .3;
        objects[i].scaleY = yScale - .3;
      } else {
        objects[i].scaleX = xScale;
        objects[i].scaleY = yScale;
      }
      
      objects[i].left = left;
      objects[i].top = top;
      objects[i].setCoords();
      editor?.canvas.renderAll();
    }
  }

  const resizeDiv = () => {
    const selector = document.querySelector(".canvas-view");
    selector.style.maxWidth = `${editor?.canvas.backgroundImage.width * editor?.canvas.backgroundImage.scaleX}px`;
    if (window.innerWidth < 1367) {
      const sideNaveContainer = document.querySelector(".side-nav-container");
      const sideNave = document.querySelector(".side-nav-section");
      if (window.innerWidth <= 1024) {
        sideNave.style.top = `${(editor?.canvas.backgroundImage.height * editor?.canvas.backgroundImage.scaleY) + 64}px`;
        sideNave.style.maxHeight = `${(screen.height - (editor?.canvas.backgroundImage.height * editor?.canvas.backgroundImage.scaleY))}px`;
        sideNaveContainer.style.height = `${(screen.height - (editor?.canvas.backgroundImage.height * editor?.canvas.backgroundImage.scaleY)) - 128}px`;
      } else {
        sideNave.style.top = `64px`;
        sideNave.style.maxHeight = `${(screen.height)}px`;
        sideNaveContainer.style.height = `${(screen.height) - 128}px`;
      }
    }
  }

  return (
    <div className="canvas-wrapper ">
      <FabricJSCanvas className={`canvas-view shadow-lg ${viewCanvas ? '' : 'hide'}`} onReady={onReady} />
      <div className={`toggle-right-menu ${showRightMenu ? 'active' : ''}`}>
        {showRightMenu && (
          <>
            <div className="header">
              <h5>Product</h5>
              <p onClick={() => { setShowRightMenu(false); contextData['resetIconMenu']() }}><MdClose size={25} /></p>
            </div>
            <div className="content">
              {
                Object.keys(selectedIcon).length !== 0 && (
                  <IconDetail {...selectedIcon} />
                )
              }
            </div>
          </>
        )}

      </div>
      {video}
    </div>
  )
}

export default RenderCanvas;