import axios from 'axios';

export const  axiosInstance = axios.create({
  baseURL: "https://us-central1-dwp-csi.cloudfunctions.net/webApi",
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin':'*',
    'Authorization': `Bearer ${window.localStorage.getItem('token')}`
  },
});

axiosInstance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  const status = error?.response?.status;
  if(status === 403) {
    window.location.href = '/auth/login'
  } else {
    return Promise.reject(error);
  }
});