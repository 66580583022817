import { Fragment } from "react"

const IconDetail = (props) => {
  const { detail, name } = props;
 
  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12 d-flex justify-content-center">
          <img className="img-thumbnail" src={detail.image} alt="Product" />
        </div>
        <div className="col-sm-12 mt-4">
          <h5 className="text-break">{name}</h5>
        </div>
        <div className="col-sm-12 mt-4">
          <p className="text-break">{detail.description}</p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          {
            detail?.customObject?.map((d,i) => {
              return (
                  <div key={i} className="d-flex"> 
                    <p><strong> { d.label }: &nbsp; </strong></p> <p><span> { d.value } </span></p>
                  </div>
              )
            })
          }
        </div>
      </div>

    </Fragment>
  )
}

export default IconDetail;