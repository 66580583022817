import React, { useContext, useState, useEffect } from "react"
import { auth } from "../firebase"
import { signInWithEmailAndPassword ,sendPasswordResetEmail ,updatePassword , updateEmail } from "firebase/auth"

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)
  const [edit, setEdit] = useState(false);
  const [view, setView] = useState(false);

  // function signup(email, password) {
  //   return auth.createUserWithEmailAndPassword(email, password)
  // }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
  }

  function logout() {
    return auth.signOut()
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email)
  }

  function updatedEmail(email) {
    return updateEmail(currentUser, email)
  }

  function changePassword(password) {
    return updatePassword(currentUser, password)
  }


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const value = {
    currentUser,
    login,
    logout,
    resetPassword,
    updatedEmail,
    changePassword,
    edit,
    setEdit,
    setView,
    view
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}