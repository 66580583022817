import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AiFillEdit, AiFillDelete, AiFillEye } from "react-icons/ai";
import Modal from 'react-bootstrap/Modal';
import Loader from "../Shared/Loader";
import { MdArrowBackIosNew } from 'react-icons/md';
import { HiOutlinePlus } from 'react-icons/hi';

import UserDetail from "./View";
import UserEdit from "./Edit";
import UserAdd from "./Add";

import { getUserList, deleteUser } from "../../services/user";

const User = () => {
  const [action, setAction] = useState('');
  const [show, setShow] = useState(false);
  const [users, setUsers] = useState([]);

  const [user, setUser] = useState({})

  useEffect(() => {
    getUserList().then((d) => {
      if (d?.users) {
        setUsers(d.users);
      }
    })
  }, [])

  const Action = (id) => {
    return (
      <div className="flex">
        <i className="m-2" onClick={() => { viewUser(id) }}><AiFillEye size={20} /></i>
        <i className="m-2" onClick={() => { editUser(id) }}><AiFillEdit size={20} /></i>
        <i className="m-2" onClick={() => { deleteUserAction(id) }}><AiFillDelete size={20} /></i>
      </div>
    )
  }

  const column = [
    {
      name: 'Name',
      selector: row => row?.displayName,
    },
    {
      name: 'Email',
      selector: row => row.email,
    },
    {
      name: 'Actions',
      cell: (row) => Action(row.uid),
    }
  ]

  const addUser = () => {
    setAction('add');
    setShow(true);
  }

  const viewUser = (id) => {
    setAction('view');
    setShow(true);
    const result = users.find(u => u.uid === id)
    setUser(result)
  }

  const editUser = (id) => {
    setAction('edit');
    setShow(true);
    const result = users.find(u => u.uid === id)
    setUser(result)
  }

  const deleteUserAction = async (id) => {
    const result = deleteUser(id);
    if (result) {
      const filteredUser = users.filter(u => u.uid !== id);
      setUsers(filteredUser)
    }
  }

  const extendUserData = (data) => {
    setUsers([...users, data])
  }

  const updateUserData = (data) => {
    const newUsers = [...users]
    const index = newUsers.findIndex((user) => user.uid === data.uid);
    newUsers[index] = data;
    setUsers(newUsers);
  }

  const handleClose = () => {
    setShow(false);
  }

  return (
    <>
      <div className="">
        <div className="card">
          <div className="user-pannel d-flex">
            <div className="d-flex w-100 justify-content-between">
              <Link to={'/tool'} className="btn btn-outline-info"><MdArrowBackIosNew /></Link>
              <span onClick={addUser} className="btn btn-outline-info"><HiOutlinePlus /> Add User</span>
            </div>
          </div>
          {
            users.length === 0 ? (<Loader />) : (
              <DataTable
                columns={column}
                data={users}
                pagination={true}
              />)
          }
        </div>



      </div>
      <Modal show={show}>
        <Modal.Body>
          {action === 'add' && <UserAdd handleClose={handleClose} extendUserData={extendUserData} />}
          {action === 'view' && <UserDetail handleClose={handleClose} user={user} />}
          {action === 'edit' && <UserEdit handleClose={handleClose} user={user} updateUserData={updateUserData} />}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default User;

