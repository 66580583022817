const password_Validation = (password) => {
    var validRegex =
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;

    if (password.match(validRegex)) {
        return true;
    } else {
        return false;
    }
};

export default password_Validation;