import React, { useState, useEffect } from 'react';
import './App.css';

import './assets/css/bootstrap.min.css'

import { AuthProvider } from "./context/AuthContext";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Layout from './Components/Layout';
import Home from './Components/Home';
import User from './Components/User';

import Login from "./Components/Auth/Login";
import ForgotPassword from "./Components/Auth/ForgotPassword";
import ChangePassword from "./Components/Auth/ChangePassword";
import RenderCanvas from './Components/Canvas';
import SceneForm from './Components/Scene';

import { SceneProvider } from './context/SceneContext';
import { getScene } from './services/sceneService';

function App() {
  const [scenes, setScenes] = useState([]);

  useEffect(() => {
    getScene().then(data => {
      setScenes(data);
    })

  }, []);

  return (
    <BrowserRouter>
      <AuthProvider>
        <SceneProvider value={{ scenesData: scenes, selectedScene: scenes.find(s => s.root === true) }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/tool" element={<Layout />} >
              <Route index element={<RenderCanvas />} />
            </Route>

            <Route path="user" element={<User />} />
            <Route path="user/password/change" element={<ChangePassword />} />
            <Route path="/auth/login" element={<Login />} />
            <Route path="/auth/password/reset" element={<ForgotPassword />} />
            <Route path="*" element={<Navigate to="/" replace />}/>
          </Routes>
        </SceneProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;

