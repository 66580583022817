import { useState } from "react";
import { updateUser } from "../../../services/user";

const UserEdit = (props) => {
  const { handleClose, user, updateUserData } = props;

  const [updatedUser, setUpdatedUser] = useState({
    id: user.uid,
    displayName: user?.displayName || '',
    password: ''
  });

  const [error, setError] = useState('')

  function handleChange(event) {
    setError('');
    setUpdatedUser({
      ...updatedUser,
      [event.target.name]: event.target.value
    })
  }

  async function save() {
    if(!updatedUser.password) delete updatedUser.password
    const user = await updateUser(updatedUser.id, updatedUser);
    if(user) {
      updateUserData(user);
      handleClose()
    } else {
      setError('Invalid data!');
    }
  }

  return (
    <>
      <div>
        <div className="title">
          <h4>Update User</h4>
        </div>
        <div>
          <form>
            <div className="form-group">
              <label htmlFor="displayName">Full Name</label>
              <input type="text" className="form-control" name="displayName" id="displayName" value={updatedUser.displayName} onChange={handleChange} />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="password">Password</label>
              <input type="text" className="form-control" name="password" id="password" value={updatedUser.password} onChange={handleChange} />
            </div>
          </form>
          <div>
            <p className="text-danger mt-2">{error}</p>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <button className="btn btn-default" onClick={() => { handleClose() }}>Close</button>
          <button className="btn btn-default" onClick={save}>Save</button>
        </div>
      </div>
    </>
  );
}

export default UserEdit;