import { axiosInstance } from "../request";

export const createUser = async (payload) => {
  try {
    const result = await axiosInstance.post(`/user`, payload);
    return result;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const updateUser = async (id, payload) => {
  try {
    const result = await axiosInstance.put(`/user/${id}`, payload);
    return result?.data;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const getUserList = async () => {
  let result;
  try {
    const response = await axiosInstance.get("/user/");
    result = response.data;
    return result;
  } catch (error) {
    console.log(error.response.data);
  }
  return result;
};

export const getUser = async (id) => {
  let result;
  try {
    const response = await axiosInstance.get(`/user/${id}`);
    result = response.data;
    return result;
  } catch (error) {
    console.log(error);
  }
  return result;
};

export const deleteUser = async (id) => {
  try {
    await axiosInstance.delete(`/user/${id}`);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};