import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import { getAuth } from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyAdPUrZdguNQ0v3KUrPuOzF5yO9UJ8_b88",
  authDomain: "dwp-csi.firebaseapp.com",
  projectId: "dwp-csi",
  storageBucket: "dwp-csi.appspot.com",
  messagingSenderId: "676845057948",
  appId: "1:676845057948:web:341b93a8aa34fbea2d3a59",
  measurementId: "G-K9N52LYBFC"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const db = app.firestore();
const auth = getAuth();

export {
  app,
  db,
  auth
};