import React, { useContext } from 'react';
import './index.css';

import { Outlet, Link, Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { Fragment, useState } from "react";

import Form from 'react-bootstrap/Form';
import SceneContext from '../../context/SceneContext';

const Layout = () => {

  const contextData = useContext(SceneContext);
  const pathname = window.location.pathname;
  let sceneView = false;
  let sideNaveView = false;
  if (pathname === '/tool') {
    sideNaveView = true;
    sceneView = true;
  }

  if (window.innerWidth < 1367 && window.innerWidth > 1024) {
    sideNaveView = false;
  }

  const [showSideNave, setShowSideNave] = useState(sideNaveView);
  const [activeScene, setActiveScene] = useState('Overview');
  const [iconState, setIconState] = useState(true);
  const [showSceneMenu, setShowSceneMenu] = useState(sceneView);

  const navigate = useNavigate();
  const { currentUser, logout } = useAuth();

  if (!currentUser) {
    return <Navigate to="/auth/login" replace />;
  }

  function handleSceneChange() {
    setActiveSceneMenu(contextData.selectedScene);
  }

  function handleProductView(icon) {
    setActiveIconMenu(icon)
  }

  contextData['handleSceneChange'] = handleSceneChange;
  contextData['handleProductView'] = handleProductView;
  contextData['resetIconMenu'] = resetIconMenu;

  async function logOut(event) {
    event.preventDefault();
    await logout();
    window.localStorage.removeItem('token');
    navigate("/auth/login", { replace: true });
  }

  function overview() {
    const scene = contextData?.scenesData.find(s => s.root === true);
    setActiveScene(scene.id);
    contextData['renderScene'](scene);
  }

  function toggleIcon() {
    setIconState(!iconState);
    contextData['showHideIcon'](!iconState);
  }

  function toggle() {
    setShowSideNave(!showSideNave);
  }

  function handleSelectView(event) {
    const scene = contextData?.scenesData.find(s => s.id === event.target.value);
    setActiveScene(event.target.value);
    contextData['renderScene'](scene);
  }

  function handleProductClick(sceneId, iconId) {
    if (sceneId && iconId) {
      const scene = contextData?.scenesData.find(s => s.id === sceneId);
      if (activeScene !== scene.id) {
        setActiveScene(scene.id);
        contextData['renderScene'](scene);
      }
      contextData['handleIconClick'](iconId, scene);
    }
  }

  function setActiveSceneMenu(scene) {
    const selectors = document.querySelectorAll(".scene-selector");
    selectors.forEach(s => {
      s.style.backgroundColor = "#fff";
      s.style.color = "#000"
    })
    resetIconMenu();
    const element = document.getElementById(scene.id);
    if (element) {
      element.style.backgroundColor = "#000";
      element.style.color = "#fff";
    }
  }

  function resetIconMenu() {
    const iselectors = document.querySelectorAll(".icon-selector");
    iselectors.forEach(s => {
      s.style.backgroundColor = "#fff";
      s.style.color = "#000"
    })
  }

  function setActiveIconMenu(icon) {
    resetIconMenu();
    const element = document.getElementById(icon.iconId);
    if (element) {
      element.style.backgroundColor = "#999";
      element.style.color = "#fff";
    }
  }

  function filterData(arrayData, key) {
    const seen = new Set(); 
    const filteredArr = arrayData.filter(el => {
      const duplicate = seen.has(el[key]);
      seen.add(el[key]);
      return !duplicate;
    });
    return filteredArr;
  }

  return (
    <Fragment>
      <div className="d-flex view-container">
        <div className={`side-nav-section border-end ${showSideNave ? 'active' : 'deactive'}`}>
          <div className="logo-section bg-dark">
            <a className="navbar-brand" href="/"><img src="iconsjll/G4SLogoTrans.png" width="70px" /></a>
          </div>
          <div className="side-nav-container">
            <ul className="side-nav-menu">
              {contextData?.scenesData && (
                filterData(contextData?.scenesData, 'label').map((data, i) => {
                  return (
                    <div key={i}>
                      <li key={i} className="scene-selector label" id={data?.id}> {data.label}</li>
                      <ul className="p-0">
                        {filterData(data.icons, 'name').map((l, i) => {
                          return (
                            <li key={i} className="icon-selector links" id={l?.iconId} onClick={() => handleProductClick(data?.id, l?.iconId)} >
                              <img className='mr-4' src={l.iconPath} width={20} alt="📦" />  {l.name}
                            </li>)
                        })}
                      </ul>
                    </div>
                  )
                }))
              }
            </ul>
          </div>
          <div className='side-nav-footer'>
            <a href='/'>
              <img src="iconsjll/PoweredByThevirtulab.svg" width={150} alt="TheVirtulab" />
            </a>
          </div>
        </div>
        <div className={`content-main-section ${showSideNave ? 'active' : 'deactive'}`}>
          <nav className="navbar navbar-expand-sm bg-dark navbar-dark border-bottom">
            <div className="container-fluid">
              <div className="row w-100">
                <div className="col-sm-1">
                  <button className="btn btn-default" type="button" onClick={toggle}>
                    <span className="navbar-toggler-icon"></span>
                  </button>
                </div>
                <div className="col-sm-11">
                  <div className="navbar-collapse d-none d-lg-block">
                    <div className="row w-100">
                      <div className="col-sm-5 col-lg-7">
                        {
                          showSceneMenu && (
                            <Fragment>
                              <a href="/doc/G4S_CSI_Project_V1.pdf" className="btn btn-secondary m-2 mr-4 btn-sm" style={{ width: '150px', margin: 'auto' }} target="_blank">How to use tool</a>
                              <button className="btn btn-secondary m-2 btn-sm" onClick={toggleIcon}>Toggle Icons</button>
                              <button className="btn btn-secondary m-2 btn-sm" onClick={overview}>Overview</button>
                            </Fragment>
                          )
                        }

                      </div>
                      <div className="col-sm-4 col-lg-2 m-auto">
                        {
                          showSceneMenu && (
                            <Form.Select aria-label="Default select example" className="fs-6 lh-sm" onChange={handleSelectView} value={activeScene}>
                              {
                                filterData(contextData.scenesData, 'label').map((s, i) => {
                                  return (<option value={s.id} key={i}>{s.label}</option>)
                                })
                              }
                            </Form.Select>
                          )
                        }

                      </div>
                      <div className="col-sm-3 col-lg-3 d-flex justify-content-end">
                        <ul className="navbar-nav">
                          <li className="nav-item">
                            <Link to='/' className="nav-link">Home</Link>
                          </li>
                          <li className="nav-item">
                            <a href='/tool' className="nav-link">Tool</a>
                          </li>
                          <li className="nav-item">
                            <a href='/user' className="nav-link">Users</a>
                          </li>
                          {
                            currentUser ?
                              (<li className="nav-item">
                                <a className="nav-link" type='button' onClick={logOut}>Logout</a>
                              </li>) :
                              (<li className="nav-item">
                                <Link to={"/auth/login"} className="nav-link">Login</Link>
                              </li>)
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
          <div className="main-content">
            <Outlet />
          </div>
        </div>
      </div>
      {/* <div className="row bg-dark footer-section">
        <div className="col-sm-12 text-center p-3">
          <img src="iconsjll/PoweredByThevirtulab.svg" width={150} />
        </div>
      </div> */}
    </Fragment>

  );
}

export default Layout;