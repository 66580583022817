import { db } from '../firebase';
import axios from 'axios';

// export const getScene = async () => {
//   try {
//     const data = []
//     const getFromFirebase = db.collection("Scene");
//     const snapshot = await getFromFirebase.get();
//     for (let doc of snapshot.docs) {
//       data.push({
//         ...doc.data(),
//         uid: doc.id
//       }
//     )
//   }
//   return data;
//   } catch (error) {
//   }
// }

export const getScene = async () => {
  try {
    const url = `${window.location.origin}/data/data.json`;
    const res = await axios.get(url);
    return res.data;
  } catch (error) {
  }
}


export const createScene = async (scene) => {
  try {
    return new Promise((resolve, reject) => {
      db.collection("Scene").add(scene).then((docRef) => {
         resolve(docRef);
      }).catch((e) => {
         reject(e);
      })
    })
  } catch (error) {
    return false;
  }
}

export const updateScene = async (scene, id) => {
  try {
    return new Promise((resolve, reject) => {
      db.collection("Scene").doc(id).update(scene).then(() => {
        resolve()
      }).catch((e) => {
        reject(e)
      })
    })
  } catch (error) {
    return false;
  }
}


export const deleteScene = async (id) => {
  try {
    return new Promise((resolve, reject) => {
      db.collection("Scene").doc(id).delete().then(() => {
      resolve()
      }).catch((e) => {
      reject(e)
      })
    })
  } catch (error) {
    return false;
  }
}