import React, { useRef, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";

import Logo from "../../../assets/image/g4slogo.png";

export default function ChangePassword() {
	const newPassword = useRef()
	const currentPassword = useRef()
	const { changePassword } = useAuth()
	const [error, setError] = useState("")
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()

	async function handleChangePassword(e) {
		e.preventDefault();
		setLoading(true);
		setError("");
		try {
			await changePassword(newPassword.current.value)
			navigate("/auth/login", { replace: true });
		} catch {
			setError("Wrong password entered!")
		}
		setLoading(false)
	}

	return (
		<div className="auth-layout">
			<div className="auth-container">
				<div className="image-wapper">
					<h1>Welcome Back</h1>
				</div>
				<div className="form-wapper">
					<div className="form-header text-center">
						<div className="d-flex justify-content-center w-100">
							<Link to={'/'} >
								<img src={Logo} width="100px" alt="Logo" />
							</Link>
						</div>
						<h2>Change Password</h2>
					</div>
					<div className="form-body">
						<form>
							<div className="input-wrapper">
								<label htmlFor="currentPassword">Current Password</label>
								<input id="currentPassword" type="text" name="currentPassword" placeholder="********" ref={currentPassword} />
							</div>
							<div className="input-wrapper">
								<label htmlFor="newPassword">New Password</label>
								<input id="newPassword" type="text" name="newPassword" placeholder="********" ref={newPassword} />
							</div>
							<button type="button" disabled={loading} onClick={handleChangePassword}>Change Password</button>
							<Link to={'/auth/login'} className="anchor-btn">Back To Login </Link>
						</form>
					</div>
					{
						error && (
							<div className="form-footer text-center">
								<p className="error">Invalid credentials!</p>
							</div>
						)
					}
				</div>
			</div>
		</div>
	)
}