export const backButtonProps = {
  onClick: 'handleBackButtonClick',
  width: 40,
  height: 40,
  fill: 'black',
  opacity: '1',
  top: 400,
  left: 20,
  angle: 270,
  hoverCursor: "pointer",
  selectable: false,
  type: "backButton"
}

export const rectCommonProps = {
  fill: '',
  stroke: 'red',
  strokeWidth: 3,
  opacity: 0.4,
  hoverCursor: 'pointer',
  selectable: false,
  rx: 5,
  ry:5,
}

export const iconCommonProps = {
  type: "icon", 
  opacity: 0.9, 
  hoverCursor: "pointer", 
  selectable: false,
  scaleX: .7, 
  scaleY: .7,
}

export const textCommonProps = {
  type: 'text',
  fontSize: 16,
  backgroundColor: 'white',
  editable: false,
  fill: "#333333",
  textAlign: 'center',
}