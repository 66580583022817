import React, { useRef, useState } from "react"
import { useAuth } from "../../../context/AuthContext"
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../../assets/image/g4slogo.png";

export default function ForgotPassword() {
	const emailRef = useRef()
	const { resetPassword } = useAuth()
	const [error, setError] = useState("")
	const [message, setMessage] = useState("")
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()

	async function handlePassword(e) {
		e.preventDefault()
		try {
			setMessage("")
			setError("")
			setLoading(true)
			await resetPassword(emailRef.current.value)
			setMessage("Check your inbox for further instructions")
			navigate("/auth/login", { replace: true });
		} catch {
			setError("Failed to reset password")
			console.log(error)
		}

		setLoading(false)
	}

	return (
		<div className="auth-layout">
			<div className="auth-container">
				<div className="image-wapper">
					<h1>Welcome Back</h1>
				</div>
				<div className="form-wapper">
					<div className="form-header text-center">
						<div className="d-flex justify-content-center w-100">
							<Link to={'/'} >
								<img src={Logo} width="100px" />
							</Link>
						</div>
						<h2>Reset Password</h2>
					</div>
					<div className="form-body">
						<form>
							<div className="input-wrapper">
								<label htmlFor="email">Email</label>
								<input id="email" type="email" name="email" placeholder="Email" ref={emailRef} />
							</div>
							<button type="button" disabled={loading} onClick={handlePassword}>Reset Password</button>
							<Link to={'/auth/login'} className="anchor-btn">Back To Login </Link>
						</form>
					</div>
					{
						error && (
							<div className="form-footer text-center">
								<p className="error">Invalid credentials!</p>
							</div>
						)
					}
					{
						message && (
							<div className="form-footer text-center">
								<p className="error">{message}</p>
							</div>
						)
					}

				</div>
			</div>
		</div>
	)
}