import { useState } from "react";
import { createUser } from "../../../services/user";
import email_validator from "../../../validators/emailValidation";
import password_Validation from "../../../validators/passwordValidation";

const UserAdd = (props) => {
  const { handleClose, extendUserData } = props;
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [user, setUser] = useState({
    displayName: '',
    email: '',
    password: ''
  });

  function handleChange(event) {
    setMessage('');
    setUser({
      ...user,
      [event.target.name]: event.target.value
    })
  }

  function save() {
    if (email_validator(user.email)) {
      if (password_Validation(user.password)) {
        if (user.email && user.password && user.displayName) {
          setLoading(true);
          createUser(user).then(res => {
            setLoading(false)
            if (res?.data) {
              extendUserData(res.data);
              handleClose();
            } else {
              setMessage('Invalid data!.');
            }
          })
        } else {
          setMessage('Invalid data!.');
        }
      } else {
        setMessage('Invalid Password ! password should be  between 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter')
      }

    } else {
      setMessage('Invalid Email')
    }

  }


  return (
    <>
      <div>
        <div className="title">
          <h4>Add new user</h4>
        </div>
        <div>
          <form>
            <div className="form-group">
              <label htmlFor="displayName">Full Name</label>
              <input type="text" className="form-control" name="displayName" id="displayName" value={user.displayName} onChange={handleChange} required />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="email">Email</label>
              <input type="email" className="form-control" name="email" id="email" value={user.email} onChange={handleChange} required />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="password">Password</label>
              <input type="text" className="form-control" name="password" id="password" value={user.password} onChange={handleChange} required />
            </div>
          </form>
          <div>
            <p className="text-danger mt-2">{message}</p>
          </div>
        </div>
        <div className="d-flex justify-content-end mt-4">
          <button className="btn btn-secondary m-2" onClick={() => { handleClose() }}>Close</button>
          <button className="btn btn-primary m-2" type="button" disabled={loading} onClick={save}>
            {loading && (<span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>)}
            {loading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </div>
    </>
  );
}

export default UserAdd;